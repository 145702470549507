import * as yup from "yup";
import { uniqueId } from "./commonUtils";
import { SIDE } from "./interfaces";

export const gender = [
  {
    id: "M",
    label: "Male"
  },
  {
    id: "F",
    label: "Female"
  }
];

export const boneType = [
  {
    id: "TIBIAS",
    label: "Tibia",
  },
  {
    id: "FEMURS",
    label: "Femur",
  },
]

export const operationType = [
  {
    id: "TIBIAS",
    label: "Tibias"
  },
  {
    id: "FEMURS",
    label: "Femurs"
  },
  {
    id: "RIGHT_TIBIA",
    label: "Right Tibia"
  },
  {
    id: "LEFT_TIBIA",
    label: "Left Tibia"
  },
  {
    id: "RIGHT_FEMUR",
    label: "Right Femur"
  },
  {
    id: "LEFT_FEMUR",
    label: "Left Femur"
  }
];

export const initialGap = [
  {
    id: "0.5",
    label: "0.5 cm"
  },
  {
    id: "0.6",
    label: "0.6 cm"
  },
  {
    id: "0.7",
    label: "0.7 cm"
  },
  {
    id: "0.8",
    label: "0.8 cm"
  }
];

export const nailType = [
  {
    id: "0.0052",
    label: "0.0052 cm"
  },
  {
    id: "0.00378",
    label: "0.00378 cm"
  }
];

export const personalInfoInputs = (countries: any) => [
  {
    id: "externalId",
    label: "External ID",
    type: "text",
    required: true,
    maxLength: 250
  },
  {
    id: "login",
    label: "Login",
    type: "text",
    required: true,
    maxLength: 70,
    shouldDisable: true,
  },
  {
    id: "firstName",
    label: "First Name",
    type: "text",
    required: true,
    maxLength: 30
  },
  {
    id: "phone",
    label: "Phone Number",
    type: "tel",
    required: true,
    shouldDisable: false,
  },
  {
    id: "lastName",
    label: "Last Name",
    type: "text",
    required: true,
    maxLength: 30
  },
  {
    id: "email",
    label: "Email",
    type: "email",
    required: true
  },
  {
    id: "preferredName",
    label: "Preferred Name",
    type: "text",
    maxLength: 30
  },
  {
    id: "dateOfBirth",
    label: "Date of Birth",
    type: "date"
  },
  {
    id: "country",
    label: "Country",
    type: "select",
    options: countries,
    required: true
  },
  {
    id: "gender",
    label: "Gender",
    type: "select",
    options: gender,
    required: true
  },
  {
    id: "surgeryDate",
    label: "Surgery Date",
    type: "date"
  },
];

export const treatmentInfoInputs = [
  {
    id: "operationType",
    label: "Operation Type",
    type: "select",
    options: operationType
  },
  {
    id: "leftFemurLengthMm",
    label: "Left Femur, cm",
    placeholder: "Left Femur",
    type: "text",
    maxLength: 5
  },
  {
    id: "initialGapMm",
    label: "Initial Gap, cm",
    type: "select",
    options: initialGap
  },
  {
    id: "rightFemurLengthMm",
    label: "Right Femur, cm",
    placeholder: "Right Femur",
    type: "text",
    maxLength: 5
  },
  {
    id: "nailTypeMm",
    label: "Nail Type, cm",
    type: "select",
    options: nailType
  },
  {
    id: "leftTibiaLengthMm",
    label: "Left Tibia, cm",
    placeholder: "Left Tibia",
    type: "text",
    maxLength: 5
  },
  {
    id: "initialHeightMm",
    label: "Initial Height, cm",
    type: "text",
    maxLength: 6
  },
  {
    id: "rightTibiaLengthMm",
    label: "Right Tibia, cm",
    placeholder: "Right Tibia",
    type: "text",
    maxLength: 5
  },
  {
    id: "targetDistractionMm",
    label: "Target Distraction, cm",
    placeholder: "Target Distraction",
    type: "text",
    maxLength: 5
  },
  {
    id: "wingSpanMm",
    label: "Wingspan, cm",
    placeholder: "Wingspan",
    type: "text",
    maxLength: 6
  }
];

export const requiredFieldsPatient = [
  "externalId",
  "login",
  "firstName",
  "lastName",
  "email",
  "phone",
  "country",
  "gender"
];

export const validationSchemaPatient = yup.object({
  externalId: yup.string().required("This field is required"),
  login: yup
    .string()
    .required("This field is required")
    .matches(
      /^[a-zA-Z0-9]*(([-._][a-zA-Z0-9]([a-zA-Z0-9]*))+)?$/,
      "Please check the data you've entered"
    ),
  firstName: yup
    .string()
    .required("This field is required")
    .matches(
      /^[A-Z]([a-z]*)(([\s'-][A-Z]([a-z]*))+)?$/,
      "Please check the data you've entered"
    ),
  lastName: yup
    .string()
    .required("This field is required")
    .matches(
      /^[A-Z]([a-z]*)(([\s'-][A-Z]([a-z]*))+)?$/,
      "Please check the data you've entered"
    ),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("This field is required"),
  phone: yup
    .string()
    .required("This field is required")
    .min(7, "Please check the data you've entered")
    .max(17, "Please check the data you've entered")
    .matches(/^\+\d+$/, "Please check the data you've entered"),
  preferredName: yup
    .string()
    .nullable()
    .matches(
      /^[A-Z]([a-z]*)(([\s'-][A-Z]([a-z]*))+)?$/,
      "Please check the data you've entered"
    ),
  country: yup.string().nullable().required("This field is required"),
  gender: yup.string().required("This field is required"),
  leftFemurLengthMm: yup
    .string()
    .nullable()
    .matches(/^\d*\.?\d*$/, "Only Numeric characters allowed"),
  rightFemurLengthMm: yup
    .string()
    .nullable()
    .matches(/^\d*\.?\d*$/, "Only Numeric characters allowed"),
  leftTibiaLengthMm: yup
    .string()
    .nullable()
    .matches(/^\d*\.?\d*$/, "Only Numeric characters allowed"),
  rightTibiaLengthMm: yup
    .string()
    .nullable()
    .matches(/^\d*\.?\d*$/, "Only Numeric characters allowed"),
  initialHeightMm: yup
    .string()
    .nullable()
    .matches(/^\d*\.?\d*$/, "Only Numeric characters allowed"),
  targetDistractionMm: yup
    .string()
    .nullable()
    .matches(/^\d*\.?\d*$/, "Only Numeric characters allowed"),
  wingSpanMm: yup
    .string()
    .nullable()
    .matches(/^\d*\.?\d*$/, "Only Numeric characters allowed")
});

export const validationSchemaTreatPlanTemp = yup.object({
  name: yup
    .string()
    .required("This field is required")
    .matches(/([A-Za-z][1-9]*)/, "Please check the data you've entered"),
  operationType: yup.string().required("This field is required"),
  nailTypeMm: yup.string().required("This field is required"),
  initialGapMm: yup.string().required("This field is required")
});

export const initialPatientValues = {
  externalId: "",
  login: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: "+",
  preferredName: "",
  dateOfBirth: null,
  country: "",
  gender: "",
  surgeryDate: null,
  operationType: "",
  initialGapMm: "",
  nailTypeMm: "",
  initialHeightMm: "",
  targetDistractionMm: "",
  leftFemurLengthMm: "",
  rightFemurLengthMm: "",
  leftTibiaLengthMm: "",
  rightTibiaLengthMm: "",
  wingSpanMm: ""
};

export const initialTreatmentTemplateValues = {
  name: "",
  operationType: "",
  nailTypeMm: "",
  initialGapMm: ""
};

export const treatmentDetailsInputs = [
  {
    id: "name",
    label: "Plan Name",
    type: "text",
    required: true,
    maxLength: 70
  },
  {
    id: "nailTypeMm",
    label: "Nail Type, cm",
    type: "select",
    options: nailType,
    required: true
  },
  {
    id: "initialGapMm",
    label: "Initial Gap, cm",
    type: "select",
    options: initialGap,
    required: true
  },
  {
    id: "operationType",
    label: "Bone Type",
    type: "select",
    options: boneType,
    required: true
  }
];

export const defaultPeriod = {
  id: uniqueId(),
  clicksPerDay: null,
  distractionMm: null,
  duration: null,
  totalClicks: null,
  sortOrder: 1,
  startDate: "",
  endDate: "",
  side: SIDE.LEFT,
};
