import differenceInDays from "date-fns/differenceInDays";
import { mmToCm } from "./commonUtils";
import { PatientControlsPatientType as PatientType } from "./interfaces";
import addDays from "date-fns/addDays";
import format from "date-fns/format";
import {ExercisePerformanceRawDataItem} from "../types/exercisePerformance";

type FormattedClick = {
  date: string;
  rightAccidentalClicks: number;
  leftAccidentalClicks: number;
  rightActualClicks: number;
  leftActualClicks: number;
  rightPainLevel: number;
  leftPainLevel: number;
  rightPlannedClicks: number;
  leftPlannedClicks: number;
  rightTimeSpent: number;
  leftTimeSpent: number;
}

type CellsWidthType = { width: number; }[];

export const actions = [
  {
    id: "report",
    label: "Report",
    description: "",
  },
  {}
];

export const formatClicksForReport = (clicks: any[]): FormattedClick[] => {
  /*
    This functions takes all the clicks for the current patient that we got from backend,
    and formats the array to be used later to construct the report.
  */
  return clicks.map(({
    date,
    leftAccidentalClicks,
    rightAccidentalClicks,
    leftActualClicks,
    rightActualClicks,
    leftPainLevel,
    rightPainLevel,
    leftPlannedClicks,
    rightPlannedClicks,
    leftTimeSpent,
    rightTimeSpent,
  }) => ({
    date: date ?? "",
    rightAccidentalClicks: rightAccidentalClicks ?? 0,
    leftAccidentalClicks: leftAccidentalClicks ?? 0,
    rightActualClicks: rightActualClicks ?? 0,
    leftActualClicks: leftActualClicks ?? 0,
    rightPainLevel: rightPainLevel ?? 0,
    leftPainLevel: leftPainLevel ?? 0,
    rightPlannedClicks: rightPlannedClicks ?? 0,
    leftPlannedClicks: leftPlannedClicks ?? 0,
    rightTimeSpent: rightTimeSpent ?? 0,
    leftTimeSpent: leftTimeSpent ?? 0,
  }));
}

const getDefaultValue = (value: any) => value || value === 0 ? `${value}` : 'N/A';

const getReportValue = (value?: number | null) => {
  if (!value || isNaN(value)) return 'N/A';

  const cmValue = mmToCm(value);

  return `${cmValue} cm`;
}

const defaultEmptyLine = [
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
];

const treatmentDataHeaders = [
  'Date',
  'Day of lengthening',
  'Accidental Clicks (Left)',
  'Accidental Clicks (Right)',
  'Actual Clicks (Left)',
  'Actual Clicks (Right)',
  'Pain Level (Left)',
  'Pain Level (Right)',
  'Planned Clicks (Left)',
  'Planned Clicks (Right)',
  'Time Spent on Clicks (Left)',
  'Time Spent on Clicks (Right)',
];

const WIDTH_PER_SYMBOL = 1.1;
let MAX_NUM_OF_CELLS = treatmentDataHeaders.length;

const generateExerciseHeaders = (exercises: ExercisePerformanceRawDataItem[]) => {
  /*
    This function takes all the exercises for the current patient that we got from backend,
    and generates an array of additional headers to be used in the report. Here we use a Map structure
    to store exercise names.

    We also need to update the MAX_NUM_OF_CELLS variable, which is used later during
    width calculations for the report.
  */
  const exercisesMap: Record<string, number> = {};

  exercises.forEach(exercise => {
    if (!exercisesMap[exercise.exerciseGroupDto.name]) {
      exercisesMap[exercise.exerciseGroupDto.name] = 1;
    }
  });

  const exercisesHeaders = Object.keys(exercisesMap);

  if (exercisesHeaders.length) {
    MAX_NUM_OF_CELLS += exercisesHeaders.length;
  }

  return exercisesHeaders;
}

const generateExerciseRow = (exercisesHeaders: string[], exerciseDataForCurrentDate: ExercisePerformanceRawDataItem[]) => {
  return exercisesHeaders.map(header => {
    const currentExerciseForPatient = exerciseDataForCurrentDate.find(exercise => {
      return exercise.exerciseGroupDto.name === header;
    })

    if (currentExerciseForPatient) {
      let accumulativeTime = 0;
      Object.entries(currentExerciseForPatient).forEach(([key, value]) => {
        if (key.startsWith('time') && typeof value === 'number' && !isNaN(value)) {
          accumulativeTime += value;
        }
      })

      return accumulativeTime.toString();
    }

    return '-';
  });
}

const getCellsData = (patient: PatientType, headers: string[], report: FormattedClick[]): [string[][], number] => {
  /*
    This function constructs main static cells that we have in the report.
    In the cells array we have a list of titles and their values taken from the patient object.
    In the end it also adds those additional dynamic headers for exercises that we generated
    in the other function and send to this one via arguments.

    This function also generates one additional line with patients' surgery date and
    empty values for other columns. So that the surgery date row is the first one.

    It also returns a daysDifference variable which is a number of days between surgery date
    of current patient and the first date from the report.
  */
  const cells = [
    ['Patient id', getDefaultValue(patient.id)],
    ['First name', getDefaultValue(patient.firstName)],
    ['Last name', getDefaultValue(patient.lastName)],
    ['Gender', getDefaultValue(patient.gender)],
    ['Country', getDefaultValue(patient.country)],
    ['Initial Gap', getReportValue(patient.treatmentData.initialGapMm)],
    ['Initial Height', getReportValue(patient.treatmentData.initialHeightMm)],
    ['Left Femur Length', getReportValue(patient.treatmentData.leftFemurLengthMm)],
    ['Left Tibia Length', getReportValue(patient.treatmentData.leftTibiaLengthMm)],
    ['Nail Type', getReportValue(patient.treatmentData.nailTypeMm)],
    ['Operation Type', getDefaultValue(patient.treatmentData.operationType)],
    ['Right Femur Length', getReportValue(patient.treatmentData.rightFemurLengthMm)],
    ['Right Tibia Length', getReportValue(patient.treatmentData.rightTibiaLengthMm)],
    ['Target Distraction', getReportValue(patient.treatmentData.targetDistractionMm)],
    ['Wingspan', getReportValue(patient.treatmentData.wingSpanMm)],
    ['Surgery date', getDefaultValue(patient.surgeryDate)],
    ['', ''],
    ['Treatment data'],
    [...treatmentDataHeaders, ...headers]
  ];

  let daysDifference = 1;

  if (patient.surgeryDate && report[0].date) {
    daysDifference = differenceInDays(new Date(report[0].date), new Date(patient.surgeryDate)) + 1;

    cells.push([
      `${patient.surgeryDate} (Surgery date)`,
      '1',
      ...defaultEmptyLine,
    ]);

    if (daysDifference <= 20) {
      const surgeryDate = new Date(patient.surgeryDate);
      for (let i = 1; i < daysDifference - 1; i++) {
        const nextEmptyDateToFill = addDays(surgeryDate, i);
        const formattedNextEmptyDateToFill = format(nextEmptyDateToFill, 'yyyy-MM-dd');
  
        cells.push([
          `${formattedNextEmptyDateToFill}`,
          `${i + 1}`,
          ...defaultEmptyLine,
        ]);
      }
    }
  }

  return [cells, daysDifference];
}

const getRowToMerge = (cells: string[][]) => {
  /*
    This simple function is used to calculate the rows that we need to merge in the
    final report document.
  */
  return cells.findIndex(cell => cell[0] === 'Treatment data');
}

type GenerateRowsData = {
  report: FormattedClick[];
  cells: string[][];
  exercises: ExercisePerformanceRawDataItem[];
  exercisesHeaders: string[];
  days: number;
}

const generateRows = (data: GenerateRowsData): string[][] => {
  /*
    This function takes all the available treatment data from arguments
    and generates all the dynamic report rows by their dates.
    We iterate over the report variable and for each item in the array
    we get the date and all the required values to put in the row.

    Additionally for each row we search the array of exercises and if a patient
    has done any for that date, we get the time spent and put it in respective columns
    (by the exercise name)
  */
  const {report, cells, exercises, days, exercisesHeaders} = data;

  const updatedCells = JSON.parse(JSON.stringify(cells));

  report.forEach((item, index) => {
    const exerciseDataForCurrentDate = exercises?.filter(exercise => exercise.date === item.date);

    const newRow = [
      getDefaultValue(item.date),
      `${index + days}`,
      getDefaultValue(item.leftAccidentalClicks),
      getDefaultValue(item.rightAccidentalClicks),
      getDefaultValue(item.leftActualClicks),
      getDefaultValue(item.rightActualClicks),
      getDefaultValue(item.leftPainLevel),
      getDefaultValue(item.rightPainLevel),
      getDefaultValue(item.leftPlannedClicks),
      getDefaultValue(item.rightPlannedClicks),
      getDefaultValue(item.leftTimeSpent),
      getDefaultValue(item.rightTimeSpent),
    ];

    const exercisesRowItems = generateExerciseRow(exercisesHeaders, exerciseDataForCurrentDate);

    newRow.push(...exercisesRowItems);
    updatedCells.push(newRow);
  });

  return updatedCells;
}

const generateCellsWidth = (cells: string[][]) => {
  /*
    This function calculates the minimum width we need to have for each column in the document
    in order for it to be readable
  */
  const cellsWidth: CellsWidthType = [];

  // Need to generate correct width for each column depending on the longest text that we have in certain cell from that column
  for (let i = 0; i < MAX_NUM_OF_CELLS; i++) {
    let maxColumnWidth = 6;

    cells.forEach(row => {
      const rowCell = row[i];

      if (rowCell && rowCell?.length) {
        if (rowCell.length * WIDTH_PER_SYMBOL > maxColumnWidth) {
          maxColumnWidth = rowCell.length * WIDTH_PER_SYMBOL;
        }
      }
    });

    cellsWidth.push({width: maxColumnWidth});
  }

  return cellsWidth;
}

export const generateReport = (patient: PatientType, clicksData: any, exercises: ExercisePerformanceRawDataItem[]): [string[][], CellsWidthType, number] => {
  const formattedReportData = formatClicksForReport(clicksData);
  const exercisesHeaders = generateExerciseHeaders(exercises);
  const [cells, daysDifference] = getCellsData(patient, exercisesHeaders, formattedReportData);
  const rowToMerge = getRowToMerge(cells);
  const cellsWithAllData = generateRows({
    report: formattedReportData,
    cells,
    days: daysDifference,
    exercises,
    exercisesHeaders,
  })

  const cellsWidth = generateCellsWidth(cellsWithAllData);

  return [cellsWithAllData, cellsWidth, rowToMerge > 0 ? rowToMerge + 1 : 0];
}
